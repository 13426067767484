/**
 * Typography
 */
 @font-face {
    font-family: "gelato-luxe", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "gelato-luxe", sans-serif;
    font-weight: 400;
    font-style: italic;
}

 @font-face {
    font-family: "quicksand", sans-serif;
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "quicksand", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "quicksand", sans-serif;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "quicksand", sans-serif;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "quicksand", sans-serif;
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "quicksand", sans-serif;
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "quicksand", sans-serif;
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "quicksand", sans-serif;
    font-weight: 700;
    font-style: italic;
}

$font-main: "quicksand", sans-serif;
$font-title: "gelato-luxe", sans-serif;

a {
    color:inherit;
}

h1, h2, h3, h4 {
    font-family: $font-title;
    font-weight: normal;
    line-height:110%;
}