html {
  font-size:14px;
  --opacity-var: 0;
}

:root {
	--banner-height: 100vh;
  --col-intro-height: auto;
}

.help-responsive-container {
	position:fixed;
	z-index:9999;
	bottom:0;
	right:0px;
	color:#fff;
	display:flex;
	align-items: center;
	justify-content: end;
	//display:none !important;

	@media screen and (max-width: 400px) {
		display:none;
	}

	h1 {
		text-transform: uppercase;
		padding:5px 10px;
		font-size:16px !important;
    font-family: $font-main !important;
    font-weight:bold;
	}
}

.container {
  max-width: 1600px;
  margin:0 auto;
  padding-left:30px;
  padding-right:30px;

  @media screen and (min-width: 576px) {
    padding-left:50px;
    padding-right:50px;
  }
}

.img-container {
  picture {
    display:inline-block;
  }

  &.--rounded {
    picture {
      overflow: hidden;
    }
  }
}

body {
  font-weight:400;
  font-family: $font-main;

  overflow-y:hidden;
  overflow-x: hidden;

  font-size:rem(18px);
  line-height: rem(30px);

  position: relative;
  background:$cream;
}

.main-content {
  .section {
    &:not(.--intro) {
      margin-top:75px;

      @media screen and (min-width: 576px) {
        margin-top:100px;
      }
    }

    &.--intro {
      margin-top:50px;

      @media screen and (min-width: 576px) {
        //margin-top:150px;
      }

      @media screen and (min-width: 768px) {
        margin-top:100px;
      }

      @media screen and (min-width: 992px) {
        margin-top:250px;
      }

      @media screen and (min-width: 1200px) {
        margin-top:300px;
      }

      .container {
        position: relative;

        @media screen and (min-width: 992px) {
            display:flex;
        }

        @media screen and (min-width: 1400px) {
          align-items: center;
        }

        .imgs-container {
          position:relative;
          text-align: center;

          @media screen and (min-width: 992px) {
            margin-top:-50px;
            text-align: left;
          }

          @media screen and (min-width: 1400px) {
            margin-top:0px;
          }

          & > * {
            opacity: 0;
          }

          .img-container {
            &.--rounded {
              picture {
                border-radius:10px;

                @media screen and (min-width: 992px) {
                  border-radius:175px;
                }

                @media screen and (min-width: 992px) {
                  width: 325px;
                  max-width: 100%;
                }

                img {
                  width: 100%;

                  @media screen and (min-width: 992px) {
                    width: 650px;
                    margin-left:-140px;
                  }
                }
              }
            }

            &.--transparent {
              position:absolute;
              top:315px;
              left: 0;
              right:0;

              @media screen and (max-width: 991px) {
                display:none;
              }

              @media screen and (min-width: 768px) {
                top:350px;
              }

              @media screen and (min-width: 992px) {
                left: -125px;
                right:auto;
                top:225px;
              }

              @media screen and (min-width: 1200px) {
                left: -150px;
                right:auto;
                top:250px;
              }

              @media screen and (min-width: 1400px) {
                left: -175px;
              }

              @media screen and (min-width: 1600px) {
                left: -200px;
              }

              img {
                width:250px;

                @media screen and (min-width: 992px) {
                  //width: 250px;
                }

                @media screen and (min-width: 1400px) {
                  width: auto;
                }
              }
            }
          }
        }

        .center-container {
          flex-grow: 1;
          text-align: center;
          margin-top:25px;

          @media screen and (min-width: 768px) {
            margin-top:50px;
          }

          @media screen and (min-width: 992px) {
            margin-left:25px;
            margin-top:0px;
          }

          @media screen and (min-width: 1200px) {
          }

          @media screen and (min-width: 1400px) {
            position: relative;
            margin-left:-175px;
          }

          @media screen and (min-width: 1500px) {
            margin-left:-325px;
          }

          .wrapper {
            display:flex;
            justify-content: center;
            margin:0 auto;
            flex-direction: column;
            align-items: center;

            @media screen and (min-width: 992px) {
              max-width: 675px;
            }

            @media screen and (min-width: 1500px) {
              max-width: 750px;
            }

            .title-container {
              & > * {
                transform: translateY(20px);
                opacity: 0;
                transition: transform .2s opacity  .2s;
              }

              h1 {
                color:$green;
                font-size: rem(40px);

                @media screen and (min-width: 768px) {
                  font-size: rem(50px);
                }

                @media screen and (min-width: 992px) {
                  font-size: rem(60px);
                }

                @media screen and (min-width: 1400px) {
                  font-size: rem(80px);
                }
              }

              p {
                font-weight:700;

                &.title {
                  margin-top:25px;
                  font-size:rem(20px);

                  @media screen and (min-width: 1400px) {
                    margin-top:50px;
                  }
                }

                & + p {
                  margin-top:24px;
                }
              }
              
            }

            .columns-container {
              display:flex;
              margin-top:50px;
              justify-content: space-between;
              
              @media screen and (min-width: 576px) {
                justify-content: center;
              }

              @media screen and (min-width: 1200px) {
                justify-content: space-between;
              }

              .column {
                text-align: center;
                width: 45%;

                @media screen and (min-width: 576px) {
                  width: 47.5%;
                }

                @media screen and (min-width: 1400px) {
                  width: 45%;
                }
                
                .icon-container {
                  background:$white;
                  border-radius: 100%;
                  width: 80px;
                  height: 80px;
                  display:flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 auto;
                }

                p {
                  margin-top:10px;
                }
              }
            }

            .map-container {
              z-index: -1;
              opacity:0;
              margin-top:-225px;
              margin-left:-275px;

              @media screen and (min-width: 576px) {
                margin-top:-275px;
                margin-left:-300px;
              }

              @media screen and (min-width: 992px) {
                position: absolute;
                left: -340px;
                top:20%;
                margin-top:0;
                margin-left:0;
              }

              @media screen and (min-width: 1200px) {
                left: -300px;
              }

              @media screen and (min-width: 1400px) {
                left:auto;
                right: -25px;
                top: auto;
                bottom:auto;
                width: auto;
                margin-top:300px;
              }

              @media screen and (min-width: 1500px) {
                right: 0px;
              }

              svg {
                max-width: 100%;
                height: auto;

                @media screen and (min-width: 992px) and (max-width: 1400px){
                  width:700px;
                }
              }
            }

            .contact-container {
              margin-top:50px;
              text-align: center;
              font-weight:700;

              & > * {
                opacity: 0;
                transform: translateY(20px);
              }

              .uppercased {
                text-transform: uppercase;
              }

              ul {
                list-style: none;
                padding-left:0px;

                @media screen and (min-width: 576px) {
                  display:flex;
                }
                
                li {
                  span {
                    margin-left:5px;
                    margin-right:5px;

                    @media screen and (max-width: 575px) {
                      display:none;
                    }
                  }

                  a {
                    transition: all .2s;

                    &.mail {
                      text-decoration: underline;
                    }

                    @media (hover: hover) {
                      &:hover {
                          color:$green;
                      }
                    }
                  }
                }
              }

              .btn {
                margin-top:35px;
              }
            }
          }
        }
      }
    }

    &.--infos {
      &:not(.--visible) {
        .body-container {
          &:after {
            opacity: 0;
          }
        }
      }

      &.--visible {
        .body-container {
          &:after {
            opacity: 1;
          }
        }
      }

      .main-title-container {
        text-align: center;

        h2 {
          color:$green;
          margin-top:25px;
          font-size:rem(50px);

          br {
            @media screen and (max-width: 991px) {
              display:none;
            }
          }

          @media screen and (min-width: 576px) {
            font-size:rem(60px);
            margin-top:35px;
            display:block;
          }
        }
      }

      .cols-container {
        position:relative;
        margin-top:50px;
       

        @media screen and (min-width: 768px) {
          display:flex;
          justify-content: space-between;
        }

        .col-container {
          position:relative;

          @media screen and (min-width: 768px) {
            width: calc(50% - 25px);
          }

          @media screen and (min-width: 1400px) {
            width: calc(50% - 50px);
          }

          .title-container {
            text-align: center;

            button {
              @media screen and (min-width: 768px) {
                pointer-events: none;
              }
              
              .icons-container {
                .arrow-container {
                  display:none;

                  @media screen and (min-width: 768px) {
                    display:block;
                  }

                  svg {
                    height: 100px;
                  }
                }
  
                .icon-container {
                  display:inline-block;

                  svg {
                    height: 80px;
                    width: auto;
                    display: block;
                    margin:0 auto;
                  }
                }
              }

              .intro {
                text-align: center;
                display:block;

                .intro-inner {
                  display:block;
                }

                @media screen and (min-width: 768px) {
                  height: var(--col-intro-height);
                  padding-left:40px;
                  padding-right:40px;
                }

                @media screen and (min-width: 1200px) {
                  padding-left:80px;
                  padding-right:80px;
                }

                .h3-title {
                  color:$orange;
                  margin-top:25px;
                  margin-bottom:10px;
                  font-size:rem(40px);
                  font-family: $font-title;
                  display:block;
  
                  @media screen and (min-width: 576px) {
                    font-size:rem(50px);
                    margin-bottom:25px;
                  }
                } 

                strong {
                  @media screen and (min-width: 576px) {
                    display: block;
                  }
                }
              }

              .btn {
                margin-top:20px;

                @media screen and (min-width: 768px) {
                  display:none;
                }
              }
            }
          }

          .body-container {
            height: 100%;
            position:relative;

            @media screen and (min-width: 768px) {
              &:after {
                background:$white;
                content:'';
                height: 50%;
                width: 100%;
                position:absolute;
                bottom:0;
                left: 0;
                z-index: -1;
                border-radius: 34px;
                transition: all .2s;
                opacity: var(--opacity-var);
              }
            }

            .infos-container {
              @media screen and (max-width: 767px) {
                display:none;
              }
  
              .main-block {
                background-color: $white;
                padding:40px 30px;
                text-align: center;
                border-radius: 34px;
                margin-top:30px;

                @media screen and (min-width: 576px) {
                  padding:50px 30px;
                }

                @media screen and (min-width: 768px) {
                  margin-top:50px;
                }
  
                @media screen and (min-width: 992px) {
                  padding:60px 50px;
                }
  
                @media screen and (min-width: 1200px) {
                  padding:80px 100px;
                }
  
                br {
                  @media screen and (max-width: 991px) {
                    display:none
                  }
                }
  
                h4 {
                  color:$green;
                  font-size:rem(35px);
  
                  @media screen and (max-width: 1400px) {
                    br {
                      display:none;
                    }
                  }
                }

                .icon-container {
                  svg {
                    width: 75px;
                    height: auto;
                    display:block;
                    margin:15px auto 0 auto;

                    @media screen and (min-width: 576px) {
                      width: auto;
                      margin:10px auto 0 auto;
                    }
                  }

                
                }
  
                .steps-container {
                  .step-container {
                    margin-top:50px;
  
                    .number {
                      margin-bottom:25px;
                      position:relative;
                      display:inline-block;
  
                      span {
                        position:relative;
                        z-index: 2;
                        font-family: $font-title;
                        font-size: rem(50px);
                        color:$green;
                      }
  
                      &:after {
                        content:'';
                        position:absolute;
                        top:-30px;
                        left: -30px;
                        background-image: url('../assets/img/svg/number-shape.svg');
                        background-position: center center;
                        background-size: 100%;
                        background-repeat:no-repeat;
                        width: 85px;
                        height: 85px;
                        z-index: 0;
                      }
                      
                    }
  
                    .infos {
                      strong {
                        font-weight: 500;
                        text-decoration: underline;
                        display: block;
                      }
                    }
                  }
                }
  
                .agenda-container {
                  margin-top:10px;
                  
                  .table {
                    display:flex;
                    justify-content: center;
  
                    .cel {
                      display:flex;
                      flex-direction: column;
                      margin-top:10px;
  
                      .month {
                        color:$light-orange;
                        font-size:rem(14px);
                        font-weight:bold;
                      }
  
                      .icon {
                        margin-top:5px;
                        
                        padding:5px;
  
                        svg {
                          display: block;
                        }
                      }
  
                      &:not(.--no-border) {
                        .icon {
                          border-right:1px solid $green;
                          border-left:1px solid $green;
                          margin-left:-1px;
                        }
                      }
  
                      &.--no-border {
                        .icon {
                          position:relative;
  
                          @media screen and (min-width: 768px) and (max-width:991px) {
                            border-right:1px solid $green;
                          }
  
                          &:after {
                            @media screen and (min-width: 992px) {
                              content:'';
                              background:$green;
                              width: 1px;
                              height: 107px;
                              position:absolute;
                              right: 0px;
                              top:0px;
                            }
                          }
                        }
                      }
                    }
  
                    &:not(.--second) {
                      .cel {
                        &:first-child {
                          .icon {
                            border-left:1px solid $green;
                          }
                        }
                      }
                    }
                  }
                }

                .icon-orange {
                  path {
                    fill:$orange;
                  }
                }

                .legend {
                  //display:flex;
                  //text-align: left;

                  & + .legend {
                    margin-top:20px;
                  }

                  .icon {
                    margin-right:10px;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;

                    svg {
                      width: 16px;
                      margin-right:5px;
                    }
                  }
                }

                .note {
                  color:$orange;
                  font-family: $font-title;
                  margin-top:25px;
                  font-size:rem(24px);
                  transition: all .2s;

                  @media (hover: hover) {
                    cursor: pointer;

                    &:hover {
                        color:$light-green;
                    }
                  }
        
                  @media screen and (min-width: 992px) {
                    margin-top:50px;
                  }
                }
              }
            }
          }

          &.--left {
            .agenda-container {
              display:flex;
              justify-content: center;

              @media screen and (max-width: 1499px) {
                flex-wrap: wrap;
              }
            }

            .title-container {
              .icon-container {
                @media screen and (min-width: 768px) {
                  margin-right: 30px;
                }
              }
            }

            .table {
              flex-wrap: wrap;
              margin-bottom:20px;
            }

            .table-container {
              &.--second {
                .table {
                  //margin-bottom:20px;
                }
              }

              &:not(.--second) {
                .hours {
                  //margin-right: 25px;
                  //margin-left: -25px;
                }
              }
            }
          }

          &.--right {
            margin-top:50px;
            padding-top:50px;
            padding-bottom:50px;
            border-top:1px solid $orange;
            border-bottom:1px solid $orange;

            @media screen and (min-width: 768px) {
              margin-top:0px;
              padding-top:0px;
              padding-bottom:0px;
              border-top:0px solid $gray;
              border-bottom:0px solid $gray;
            }

            .title-container {
              .icon-container {
                @media screen and (min-width: 768px) {
                  margin-left: 50px;
                }
              }
            }

            .main-block {
              .icon-container {
                transform: scaleX(-1);
              }

              .agenda-container {
                display:flex;
                justify-content: center;
                
                @media screen and (max-width: 991px) {
                  flex-wrap: wrap;
                }

                @media screen and (min-width: 992px) and (max-width: 1199px){
                  .icon {
                    svg {
                      width: 20px;
                    }
                  }
                  
                  .hours {
                    font-size:rem(16px);
                  }
                }

                .table {
                  margin-bottom:20px;
                }

                .table-container {
                  &:not(.--second) {
                    .hours {
                      @media screen and (min-width: 992px) {
                        margin-right:10px;
                        margin-left: -10px;
                      }

                      @media screen and (min-width: 1200px) {
                        margin-right:25px;
                        margin-left: -25px;
                      }
                    }
                  }
                }
              }
            }
          }
    
          &.accordion-open {
            .body-container {
              .infos-container {
                display:block;
              }
            }
    
            .accordion-btn {
              span {
                &.more {
                  display:none;
                }
      
                &.less {
                  display:block;
                }
              }
            }
          }
    
          .accordion-btn {
            position:relative;
    
            span {
              transition: all .2s;

              &.text-block {
                display:block;
              }
    
              &.less {
                display:none;
              }
            }
          }
        }
      }
    }

    &.--commandes {
      .container {
        display: flex;
        justify-content: center;

        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;

        @media screen and (min-width:768px) {
          flex-direction: row;
        }

        @media screen and (min-width:992px) {
            justify-content: space-between;
        }


        @media screen and (min-width:1200px) {
          margin-top:150px;
          flex-wrap: nowrap;
        }

        & > div {
          position:relative;

          @media screen and (min-width: 992px) {
              flex-basis:0;
          }

          a {
            @media screen and (min-width: 1200px) {
              white-space: nowrap;
            }
          }

          .icon-container {
            @media screen and (max-width:991px){
              display:none;
            }

            @media screen and (min-width:992px) and (max-width: 1399px) {
              position:absolute;
              top:0;
              bottom:0;
            }

            svg {
              display: block;
              
            }
          }
        }

        .arrow-container {
          @media screen and (max-width:991px){
            display:none;
          }

          svg {
            width: 100px;
          }
        }

        .left {
          display:flex;
          align-items: center;

          @media screen and (min-width:576px) and (max-width: 991px){
            //min-width: 66%;
          }

          @media screen and (min-width:992px) {
            flex-grow: 1;
            justify-content: flex-end;
          }

          @media screen and (max-width: 1199px) {
            order:2;
          }

          .btns-container {
            display:flex;
            width: 100%;
            flex-direction: column;

            @media screen and (min-width:576px) {
              flex-direction:row;
            }

            @media screen and (min-width:992px) {
              margin-right: 25px;
              flex-direction: column;
              width: auto;
            }

            @media screen and (min-width:992px) and (max-width: 1399px) {
              margin-right:50px;
            }

            @media screen and (min-width:1400px) {
              margin-left: -50px;
              margin-right: 50px;
            }

            .btn {
              text-align: center;
              display: inline;
              flex-grow: 1;

              & + .btn {
                margin-top:20px;

                @media screen and (min-width:576px) {
                  margin-left:15px;
                  margin-top:0px;
                }

                @media screen and (min-width:992px) {
                  margin-top:30px;
                  margin-left:0px;
                }
              }
            }
          }

          .btn {
            @media screen and (min-width:1400px) {
              margin-left: -50px;
              margin-right: 50px;
            }
          }

          .icon-container {
            margin-top:-45px;

            @media screen and (min-width:992px) and (max-width: 1399px) {
              left: 0px;
            }
          }
        }

        .center {
          text-align: center;

          @media screen and (max-width: 1199px) {
            order:1;
            min-width: 100%;
            margin-bottom:25px;
          }

          @media screen and (min-width: 1200px) {
            min-width: 300px;
          }
         
          h3 {
            color:$orange;
            font-size:rem(50px);

            @media screen and (min-width: 576px) {
              font-size:rem(50px);
            }
          }
        }

        .right {
          align-items: center;
          display:flex;

          @media screen and (min-width: 992px) {
            justify-content: flex-start;
              flex-grow: 1;
            
          }
          
          @media screen and (max-width: 1199px) {
            order:3;
          }

          .btn {
            z-index: 10;
            text-align: center;
            width:100%;
            display: inline;
            margin-top:20px;

            @media screen and (min-width:768px) {
              margin-left:15px;
              margin-top:0px;
            }

            @media screen and (min-width:992px) {
              margin-left: 50px;
              width: auto;
            }

            @media screen and (min-width:1200px) {
              margin-left: 25px;
            }

            @media screen and (min-width:1400px) {
              margin-left: 50px;
            }
          }

          .icon-container {
            margin-left: -75px;

            @media screen and (min-width:992px) and (max-width: 1399px) {
              right: -50px;
            }

            svg {
              margin-top:-100px;
            }
          }
        }
      }
    }

    &.--about {
      position:relative;

      .interlude-container {
        position: absolute;
        top:0;
        left: -200px;
        z-index: -1;

        @media screen and (max-width: 767px) {
          display:none;
        }

        img {
          mix-blend-mode: multiply;
        }
      }

      .main-block {
        text-align: center;
        max-width: 900px;
        margin:0 auto;

        .img-container {
          position:relative;
          display: inline-block;
          
          &:before,
          &:after {
            content:'';
            position:absolute;
            bottom:0px;
            background-image: url('../assets/img/svg/carrot-about.svg');
            background-position: center center;
            background-size: 60px;
            background-repeat:no-repeat;
            z-index: 0;
            width: 100px;
            height: 100px;

            @media screen and (min-width: 576px) {
              width: 150px;
              height: 150px;
              background-size: 75px;
            }
          }

          &:before {
            left: -75px;

            @media screen and (min-width: 576px) {
              left: -105px;
            }
          }

          &:after {
            right: -75px;
            transform:scaleX(-1);

            @media screen and (min-width: 576px) {
              right: -105px;
            }
          }

          &.--rounded {
            picture {
              border-radius:150px;

              img {
                width: 230px;
              }
            }
          }
        }
        
        .body {
          h2 {
            color:$green;
            margin-top:50px;
            margin-bottom:25px;

            font-size:rem(50px);

            @media screen and (min-width: 576px) {
              font-size:rem(60px);
              margin-bottom:50px;
              margin-top:75px;
            }
          }

          .icon-container {
            svg {
              display: block;
              margin: -10px auto;
            }
          }

          p {
            & + p {
              margin-top:20px;
            }
          }
        }
      }
    }

    &.--faq {
      position:relative;

      @media screen and (max-width: 767px) {
        margin-bottom:100px;
      }

      .interlude-container {
        position: absolute;
        top:-125px;
        right: -250px;
        z-index: -1;

        @media screen and (max-width: 767px) {
          display:none;
        }
      }

      .title-container {
        text-align: center;

        h2 {
          color:$green;
          font-size:rem(50px);

          @media screen and (min-width: 576px) {
            font-size:rem(60px);
          }
        }

        .intro {
          margin-top:25px;
        }
      }

      .main-block {
        max-width: 900px;
        margin:50px auto 0px auto;
        
        .accordion {
          overflow: hidden;
        }

        .tab {
          border-bottom:1px solid $dark-gray;
          padding-bottom:15px;

          input {
            position: absolute;
            opacity: 0;
            z-index: -1;
            -webkit-tap-highlight-color: transparent !important;

            &:checked ~ .tab__content {
              max-height: 50rem;
            }

            &:checked  {
              & + .tab__label {
                pointer-events: none;
                color:$light-green;
    
                svg {
                  transform: rotate(-90deg);
                }
              }
            }
          }

          .tab__label {
            justify-content: space-between;
            display:flex;
            align-items: center;
            padding-top:15px;
            font-weight:700;
            cursor: pointer;
            transition: all .2s;
            -webkit-tap-highlight-color: transparent !important;

            @media (hover: hover) {
              &:hover {
                  color:$light-green;
              }
            }
  
            svg {
              display:block;
              transition: all .2s;
              transform: rotate(90deg);
              margin-left: 10px;
              min-width: 15px;
            }
          }

          .tab__content {
            max-height: 0;
            overflow: hidden;
            transition: all 0.35s;

            br {
              @media screen and (max-width: 767px) {
                display:none;
              }
            }

            a {
              color:$orange;
              font-weight:500;
              transition: all .2s;

              @media (hover: hover) {
                &:hover {
                    color:$light-green;
                }
              }
            }

            p {
              margin-top:10px;
              margin-bottom:10px;
            }

            ol, ul {
              li {
                margin-top:5px;
              }
            }
          }
          
        }
      }
    }
  }
}




// ERROR
.page-error {
  .navbar {
    .navbar-left,
    .navbar-right {
      display:none;
    }
  }

  .main-wrapper {
    text-align: center;
    margin-top:150px;

    @media screen and (min-width: 768px) {
      margin-top:250px;
    }

    @media screen and (min-width: 992px) {
      margin-top:300px;
    }

    @media screen and (min-width: 1200px) {
      margin-top:40vh;
    }

    .banner {
      p {
        font-size:rem(48px);
        line-height: 100%;
        font-weight:700;
        display: block;
      }
    }
  
    .main-content {
      margin-bottom:120px;
      text-align: center;
      margin-top:25px;

      a:not(.btn) {
        color:$orange;
        font-weight:500;
        transition: all .2s;

        @media (hover: hover) {
          &:hover {
              color:$light-green;
          }
        }
      }
  
      .btn-container {
        margin: 25px auto 0 auto;
        text-align: center;
      }
    }
  }
}