/**
 * Footer
 */

footer {
    margin-top: calc(var(--banner-height) * -0.5);

    .img-container {
        position:relative;
        z-index: -1;

        &:before {
            position:absolute;
            content:'';
            width: 100%;
            height: 50%;
            top:0;
            left: 0;
            background: rgb(244,237,230);
            background: linear-gradient(180deg, rgba(244,237,230,1) 0%, rgba(0,0,0,0) 100%);
        }

        picture {
            width: 100%;
        }
        img {
            width: 100%;
        }
    }

    .footer-container {
        text-align: center;

        ul {
            list-style:none;
            padding-left:0px;
        }

        .footer-top {
            .contact-container {
                text-align: center;
                font-weight:700;

                .brand {
                    margin-top:-150px;

                    @media screen and (min-width: 576px) {
                        margin-top:-200px;
                    }
                }  

                .text-container {
                    margin-top:10px;

                    @media screen and (min-width: 576px) {
                        margin-top:25px;
                    }

                    .uppercased {
                        text-transform: uppercase;
                      }
        
                      ul {
                          @media screen and (min-width: 576px) {
                            display:flex;
                            justify-content: center;
                            }
                          
                          li {
                              span {
                                  margin-left:5px;
                                  margin-right:5px;

                                  @media screen and (max-width: 575px) {
                                    display:none;
                                  }
                              }
          
                              a {
                                  transition: all .2s;
                                  text-decoration: underline;
          
                                  @media (hover: hover) {
                                      &:hover {
                                          color:$green;
                                      }
                                  }
                              }
                          }
                      }
                }

                .logo-container {
                    margin-top:30px;

                    svg {
                        width: 200px;
                        height: auto;
                        display:block;
                        margin: 0 auto;
                    }
                }
  
               
            }
        }

        .footer-bottom {
            margin-top:30px;
            margin-bottom:30px;

            @media screen and (min-width: 576px) {
                margin-top:50px;
                margin-bottom:50px;
            }

            .footer-bottom-container {
                justify-content: center;
                flex-wrap: wrap;
                display:flex;
                flex-direction: column;

                @media screen and (min-width: 576px) {
                   flex-direction: row;
                }
                
                @media screen and (min-width: 1200px) {
                    justify-content: space-between;
                }

                & > * {
                    display:flex;
                    flex-wrap: wrap;

                    @media screen and (min-width: 1200px) {
                        flex-basis:0;
                        flex-grow: 1;
                    }
                }

                .left {
                    justify-content: center;

                    @media screen and (min-width: 576px) {
                        justify-content: start;
                    }

                    @media screen and (max-width: 1199px) {
                        order:3;
                    }

                    a {
                        margin-left:5px;
                    }
                    span {
                        margin-left:5px;
                        margin-right:5px;
                    }
                }

                .middle {
                    justify-content: center;
                    min-width: 100%;

                    @media screen and (min-width: 1200px) {
                        min-width: 50%;
                    }

                    span {
                        margin-left:5px;
                        margin-right:5px;
                    }
                }

                .right {
                    justify-content: center;

                    @media screen and (min-width: 576px) {
                        justify-content: end;
                    }
                }

                a,button {
                    transition: all .2s;

                    @media (hover: hover) {
                        &:hover {
                            color:$green;
                        }
                    }
                }
            }
        }
    }
}