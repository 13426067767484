/**
 * Navbar
 */
header {
    height: 75px;
    transition: all .2s;
    position: relative;
    z-index: 9985;

    @media screen and (min-width: 576px) {
        height: 100px;
    }

    &:not(.--tiny) {
        .navbar {
            .brand {
                span {
                    &.tiny {
                        opacity: 0;
                    }

                    &.normal {
                        transition-delay: 200ms;
                    }
                }
            }
        }
    }
  
    &.--tiny {
        .navbar {
            .brand {
                span {
                    &.tiny {
                        transition-delay: 200ms;
                        opacity: 1;
                    }

                    &.normal {
                        opacity: 0;
                        transform : translateY(20px);
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.navbar {
    position: fixed;
    left: 0px;
    right: 0px;
    top:0;
    display:flex;
    align-items: center;
    height: 75px;
    background:$dark-green;

    @media screen and (min-width: 576px) {
        height: 100px;
    }

    .container {
        display:flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
        @media screen and (min-width: 575px) and (max-width: 1299px){
            padding-right:25px;
        }

        .navbar-center {
            position:absolute;
            top:17.5px;
            width: 230px;
            left: calc(50% - 115px);
            
            @media screen and (min-width: 576px) {
                top:30px;
            }

            a {
                display: flex;
                justify-content: center;

                span {
                    
                    &.tiny {
                        margin-top:-2px;
                        position:absolute;
                        transition: all .2s;
                        color:$white;

                        span {
                            display:block;
                            text-align: center;

                            &.main {
                                font-family: $font-title;
                                font-size:rem(30px);
                                
                                @media screen and (min-width: 576px) {
                                    font-size:rem(40px);
                                }
                            }

                            &.tag {
                                font-family: $font-main;
                                text-transform: uppercase;
                                font-weight: 500;
                                font-size:rem(12px);

                                @media screen and (min-width: 576px) {
                                    font-size:rem(14px);
                                    margin-top:5px;

                                }
                            }
                        }

                        svg {
                            width: 175px;

                            @media screen and (min-width: 575px) {
                                width:200px
                            }

                            @media screen and (min-width: 1400px) {
                                width: 220px;
                            }
                        }
                    }

                    &.normal {
                        position:absolute;
                        transition: all .2s;

                        svg {
                            width: 125px;
                            height: auto;

                            @media screen and (min-width: 576px) {
                                width: 150px;
                            }

                            @media screen and (min-width: 768px) {
                                width: 200px;
                            }

                            @media screen and (min-width: 1200px) {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    button {
        text-transform: uppercase;
        color:$white;
        font-size:rem(14px);

        @media screen and (max-width: 991px) {
            display:none;
        }

        @media screen and (min-width: 1200px) {
            font-size:rem(16px);
        }

        & + button {
            margin-left:20px;

            @media screen and (min-width: 1200px) {
                margin-left:25px;
            }

            @media screen and (min-width: 1300px) {
                margin-left:50px;
            }
        }

        &:not(.btn) {
            font-weight:500;
            transition: all .2s;

            @media (hover: hover) {
                &:hover {
                    //color:$green;
                    opacity: 0.6;
                }
            }
        }

        &.btn {
            @media screen and (max-width: 767px) {
                display:none;
            }

            span {
                margin-right:5px;

                @media screen and (max-width: 991px) {
                    display:none;
                }
            }
        }
    }

    .facebook-container {
        margin-right:30px;

        @media screen and (min-width: 576px) {
            margin-right:50px;
        }

        @media screen and (min-width: 1800px) {
            position: absolute;
            right: 50px;
            margin-right: 0px;
        }

        a {
            background:$white;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            display:flex;
            align-items: center;
            justify-content: center;
            transition: all .2s;

            svg {
                path {
                    transition: all .2s;
                }
            }

            @media (hover: hover) {
                &:hover {
                    background:$green;

                    svg {
                        path {
                            fill:$white;
                        }
                    }
                }
            }
        }
    }
}
