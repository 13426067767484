/**
 * Colors
 */

$light-orange:#FAB564;
$orange: #F17F33;
$light-green: #72AB65;
$green:#7D9D4B;
$dark-green:#45642A;
$cream: #F4EDE6;

$white: #ffffff;
$black: #000000;
$gray: #c4cccc;
$dark-gray:#707070;

// Default text color
$color-text: $black !default;

// Theme colors
$color-primary: $black;
$color-secondary: $black;


// Links
$color-link-color: $green !default;
$color-link-color-hover: darken($color-link-color, 20%) !default;
//$color-link-color-hover: color.adjust($color, $lightness: -20%) !default;


