/**
 * Buttons
*/

.btn {
    display: inline-block;

    &.--rounded {
        border-radius:28px;
        font-weight:700;
        transition: all .2s;
        padding:16px 24px;
        display:inline-flex;
        align-items: center;

        svg {
            margin-left:10px;
        }

        &.--orange {
            background:$orange;
            color:$white;

            @media (hover: hover) {
                &:hover {
                    background:$green;
                }
            }
        }

        &.--white {
            background:$white;
            color:$orange;

            @media (hover: hover) {
                &:hover {
                    background:$green;
                    color:$white;
                }
            }
        }
    }
}

.accordion-btn {
    /*
    @include media-breakpoint-up(lg) {
        display:none;
    }
    */
}

button,
input {
    background:none;
    border:0px;
    padding:0px;
    margin:0px;
    //line-height: 100%;
    font-size: 100%;
    cursor: pointer;
    color:inherit;
    outline: none;
    -webkit-tap-highlight-color: transparent !important;
}